<template>
  <div>
    <Card>
      <FormulateForm
        @submit="submit"
        v-model="formData"
        name="bundleForm"
        autocomplete="false"
      >
        <div class="red" v-if="formError">
          <p>
            <b>ERROR:</b>
            If you are trying to apply a discount to a single ticket tier please
            use Bulk Discounts.
          </p>
        </div>
        <div class="row">
          <FormulateInput
            type="group"
            name="tiers"
            :repeatable="true"
            label="Tiers"
            add-label="+ Add Tiers"
            :value="formData.tiers"
            #default="{ index }"
            autocomplete="false"
          >
            <div class="col quarterx3 ticket-tier-container">
              <TicketTierSelector
                v-if="isRecurringEvent"
                :error="false"
                :tiers="tiersData"
                v-model="tierholders[index]"
                @input="addRecurringTier($event, index)"
              />
              <FormulateInput
                v-else
                type="select"
                label="Ticket tier"
                :options="tierList"
                name="saleDateId"
                placeholder="Please Select"
                :bundle="true"
                @input="addTier($event, index)"
                autocomplete="false"
              />
            </div>
            <div class="col quarter">
              <FormulateInput
                type="number"
                label="Quantity"
                name="quantity"
                autocomplete="false"
              />
            </div>
          </FormulateInput>
          <div class="col flex center full">
            <span class="ticket-tier-plus-sign">+</span>
          </div>
        </div>
        <div class="row">
          <FormulateInput
            type="group"
            name="products"
            :repeatable="true"
            label="Products"
            add-label="+ Add Products"
            :value="formData.products"
            #default="{ index }"
            autocomplete="false"
          >
            <div class="col quarterx3 ticket-tier-container">
              <FormulateInput
                type="select"
                label="Product"
                :options="products"
                name="variantId"
                placeholder="Please Select"
                :bundle="true"
                @input="addProducts($event, index)"
                autocomplete="false"
              />

              <!-- <span class="ticket-tier-plus-sign">+</span> -->
            </div>
            <div class="col quarter ticket-tier-quantity">
              <FormulateInput
                type="number"
                label="Quantity"
                name="quantity"
                autocomplete="false"
              />
            </div>
            <!-- <FormulateInput
              type="money"
              label="Discount"
              :placeholder="methodTypePlaceholderDisplay"
              validation="required"
              name="methodValue"
            /> -->
          </FormulateInput>
        </div>

        <div class="row">
          <div class="col full">
            <FormulateInput
              label="Number of Bundles Available"
              type="number"
              validation="required|number|min:1,value"
              name="codeAvailable"
              placeholder="Enter Number"
              autocomplete="false"
            />
          </div>
        </div>

        <div class="row">
          <div class="col half">
            <FormulateInput
              type="dateTime"
              label="Start Date"
              placeholder="Select Start Date"
              validation="required"
              name="startDate"
              :min-date="new Date()"
              :max-date="startDateMaximumDate"
              autocomplete="false"
            />
          </div>

          <div class="col half">
            <FormulateInput
              type="dateTime"
              label="End Date"
              placeholder="Select End Date"
              validation="required"
              name="endDate"
              :min-date="endDateMinimumDate"
              :max-date="eventEndDate"
              autocomplete="false"
            />
          </div>
        </div>

        <div class="divider"></div>
        <h2>Overview</h2>
        <div class="list-bundle-container">
          <h2>Tiers</h2>
          <Card>
            <div class="header">
              <div class="item-header">Items</div>
              <div class="item-header">Quantity</div>
              <div class="item-header">Item Price</div>
              <div class="item-header">Bundled Price</div>
            </div>
            <div class="items-container" v-if="formData.tiers.length >= 1">
              <div
                class="item"
                v-for="(tier, index) in formData.tiers"
                :key="index"
              >
                <div class="items" v-if="tier.name && tier.quantity > 0">
                  <div class="item-name">{{ tier.name }}</div>
                  <div class="quantity-input-container">
                    <div class="male-counter">
                      <div class="counter">
                        <span class="minus" @click="minusTier(index)">-</span>
                        <input
                          type="number"
                          name=""
                          id=""
                          class="male-input"
                          min="1"
                          v-model="tier.quantity"
                          autocomplete="false"
                        />
                        <span class="plus" @click="plusTier(index)">+</span>
                      </div>
                    </div>
                  </div>
                  <div class="price-input-container">
                    <!-- <DiyoboInput
                      noLabel
                      type="text"
                      :val="`\$${tier.price || 0}`"
                      disabled
                    /> -->
                    <FormulateInput type="money" :value="tier.price" disabled />
                  </div>
                  <div class="price-input-container">
                    <!-- <DiyoboInput
                      noLabel
                      type="money"
                      v-model="tier.bundlePrice"
                      :val="tier.bundlePrice"
                      placeholder="Amount"
                    /> -->
                    <FormulateInput
                      type="money"
                      v-model="tier.bundlePrice"
                      :val="tier.bundlePrice"
                      placeholder="Amount"
                      validation="required"
                      :key="index"
                    />
                  </div>
                </div>
                <div class="items-coantainer" v-else>
                  <div class="items">No tiers added</div>
                </div>
              </div>
            </div>
            <div class="items-coantainer" v-else>
              <div class="items">No tiers added</div>
            </div>
          </Card>

          <h2>Products</h2>
          <Card>
            <div class="header">
              <div class="item-header">Items</div>
              <div class="item-header">Quantity</div>
              <div class="item-header">Item Price</div>
              <div class="item-header">Bundled Price</div>
            </div>
            <div class="items-container" v-if="formData.products.length >= 1">
              <div
                class="item"
                v-for="(product, index) in formData.products"
                :key="index"
              >
                <div class="items" v-if="product.title && product.quantity > 0">
                  <div class="item-name">{{ product.title }}</div>
                  <div class="quantity-input-container">
                    <div class="male-counter">
                      <div class="counter">
                        <span class="minus" @click="minusProduct(index)">
                          -
                        </span>
                        <input
                          type="number"
                          name=""
                          id=""
                          class="male-input"
                          min="1"
                          v-model="product.quantity"
                        />
                        <span class="plus" @click="plusProduct(index)">+</span>
                      </div>
                    </div>
                  </div>
                  <div class="price-input-container">
                    <DiyoboInput
                      noLabel
                      type="text"
                      :val="`\$${product.price || 0}`"
                      disabled
                    />
                  </div>

                  <div class="price-input-container">
                    <DiyoboInput
                      noLabel
                      required
                      type="money"
                      v-model="product.bundlePrice"
                      :val="product.bundlePrice"
                      placeholder="Amount"
                    />
                  </div>
                </div>
                <div class="items-coantainer" v-else>
                  <div class="items">No products added</div>
                </div>
              </div>
            </div>
            <div class="items-coantainer" v-else>
              <div class="items">No products added</div>
            </div>
          </Card>
        </div>
        <div class="divider"></div>
        <div class="row">
          <div class="col full flex center">
            <FormulateInput
              type="text"
              label="Bundle Name"
              name="name"
              placeholder="Bundle Name"
              validation="required"
              autocomplete="false"
            />
          </div>
        </div>

        <div class="row">
          <div class="col half">
            <DiyoboInput
              type="text"
              name="tfee"
              label="T-Fee"
              :val="totalTFees"
              placeholder="T-Fee"
              readOnly
            />
          </div>
          <div class="col half">
            <DiyoboInput
              type="money"
              name="bundleTotal"
              :val="totalBundle"
              label="Bundle Total"
              placeholder="Please add bundles"
              readOnly
            />
          </div>
        </div>
        <div class="divider"></div>
        <div class="row">
          <div class="col full">
            <diyobo-checkbox
              type="checkbox"
              label="Would you like to add a password to this bundle?"
              v-model="isThereAPassword"
              :checked="isThereAPassword"
            />
            <div class="form-col password" v-if="isThereAPassword">
              <FormulateInput
                type="text"
                label="Enter Password"
                name="password"
                placeholder="Password"
                validation="required"
                autocomplete="false"
              />
            </div>
          </div>
        </div>
        <div class="row btns" v-if="isEditing">
          <div class="col half">
            <FormulateInput
              class="cancel-button"
              type="button"
              @click="cancelEdit"
              name="Cancel"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="submit"
              :disabled="isSaving"
              :name="isSaving ? 'Saving...' : 'Save'"
            />
          </div>
        </div>

        <div class="row btns" v-else>
          <div class="col half">
            <diyobo-button
              class="back-events"
              @click="redirect"
              type="primary-dark"
              txt="Back to My Events"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="submit"
              :disabled="isSaving"
              :name="isSaving ? 'Submitting...' : 'Submit'"
            />
          </div>
        </div>
      </FormulateForm>
    </Card>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox";
import dayjs from "dayjs";

import LocalizedFormats from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import dateFormat from "dateformat";
import TicketTierSelector from "@/components/TicketTierSelector";

const BUNDLE_DISCOUNT = "Bundled Discount";
const ALL_TICKET_TIERS = "All Ticket Tiers";
const DOLLAR_DISCOUNT = "$ Discount";
const PERCENT_DISCOUNT = "% Discount";

dayjs.extend(LocalizedFormats);
dayjs.extend(timezone);
dayjs.extend(utc);

export default {
  name: "bundle-discount",
  components: {
    DiyoboButton,
    DiyoboInput,
    DiyoboCheckbox,
    TicketTierSelector
  },
  props: {
    eventUrl: {
      type: String,
      description: "unique url event",
      required: true
    },
    tableData: {
      type: Object,
      description: "data table display",
      required: true
    },
    tableIndex: {
      type: Number,
      desription: "index that can be use in data table",
      required: true
    },
    incrementTableIndex: {
      type: Function,
      description: "increment tableIndex + 1",
      required: true
    }
  },
  data() {
    return {
      formData: {
        tiers: [],
        products: [],
        codeAvailable: "",
        startDate: "",
        endDate: "",
        ccfee: 0,
        tfee: 0,
        password: '',
      },
      isThereAPassword: false,
      isSaving: false,
      eventEndDate: null,
      tierholders: [],
      tiers: [],
      formError: false,
      bundleId: "",
      tfees: [],
      ccfee: 0.03,
      methods: [DOLLAR_DISCOUNT, PERCENT_DISCOUNT],
      products: [],
      tierList: [],
      isEditing: false,
      //previousBundleCode used for editing tableData and matching in database
      previousBundleCode: {},

      //used for storing tier data
      tiersData: [],
      productsData: [],
      isRecurringEvent: false

      // totalCCFees: 0,
      // totalTFees: 0
    };
  },
  computed: {
    startDateMaximumDate() {
      return this.formData.endDate !== ""
        ? this.formData.endDate
        : this.eventEndDate;
    },
    endDateMinimumDate() {
      return this.formData.startDate === "" || this.formData.startDate === null
        ? new Date()
        : this.formData.startDate;
    },
    notSelectedTiers() {
      const difference = this.tierList.filter(
        ({ value: id1 }) =>
          !this.formData.tiers.some(({ saleDateId: id2 }) => id2 === id1)
      );

      return difference;
    },

    selectedTiers() {
      if (this.formData) {
        const same = this.tiersData.filter(({ id: id1 }) =>
          this.formData.tiers.some(({ tierId: id2 }) => id2 === id1)
        );

        return same;
      } else {
        return [];
      }
    },
    methodTypePlaceholderDisplay() {
      return this.formData.methodType === DOLLAR_DISCOUNT
        ? "Discount ($)"
        : "Discount (%)";
    },

    totalCCFees() {
      let tierCCFees =
        this.formData.tiers.reduce(function(a, b) {
          return (a + parseFloat(b["ccfee"])) * parseInt(b["quantity"]);
        }, 0) || 0;

      let productCCFees =
        this.formData.products.reduce(function(a, b) {
          return (a + parseFloat(b["ccfee"])) * parseInt(b["quantity"]);
        }, 0) || 0;

      return (tierCCFees + productCCFees).toFixed(2);
    },
    totalBundle() {
      let tierTotal =
        this.formData.tiers.reduce(function(a, b) {
          return a + parseFloat(b["bundlePrice"]) * parseInt(b["quantity"]);
        }, 0) || 0;

      let productTotal =
        this.formData.products.reduce(function(a, b) {
          return a + parseFloat(b["bundlePrice"]) * parseInt(b["quantity"]);
        }, 0) || 0;

      return (tierTotal + productTotal).toFixed(2);
    },
    totalTFees() {
      let tierTFees = this.formData.tiers.reduce(function(a, b) {
        if (parseFloat(b["bundlePrice"]) === 0) {
          b["dfee"] = 0;
          return a + 0;
        } else {
          return a + parseFloat(b["dfee"]) * parseInt(b["quantity"]);
        }
      }, 0);

      let productTFees = this.formData.products.reduce(function(a, b) {
        if (parseFloat(b["bundlePrice"]) === 0) {
          return a + 0;
        } else {
          return a + parseFloat(b["tfee"]) * parseInt(b["quantity"]);
        }
      }, 0);

      return (tierTFees + productTFees).toFixed(2);
    }
  },
  methods: {
    minusProduct(index) {
      let quantity = this.formData.products[index].quantity;
      if (quantity > 0) {
        this.formData.products[index].quantity =
          parseInt(this.formData.products[index].quantity) - 1;
      }
    },
    plusProduct(index) {
      this.formData.products[index].quantity =
        parseInt(this.formData.products[index].quantity) + 1;
    },
    minusTier(index) {
      let quantity = this.formData.tiers[index].quantity;

      if (quantity > 0) {
        this.formData.tiers[index].quantity =
          parseInt(this.formData.tiers[index].quantity) - 1;
      }
    },
    plusTier(index) {
      this.formData.tiers[index].quantity =
        parseInt(this.formData.tiers[index].quantity) + 1;
    },
    calculateTierFees(price, index, type) {
      const cost = Number(price);

      if (type === "tier") {
        for (let i = 0; i < this.tfees.length; i++) {
          let ele = this.tfees[i];
          if (cost >= ele.min && cost <= ele.max) {
            this.formData.tiers[index].dfee = ele.value.toFixed(2);
            i = this.tfees.length;
          } else if (cost === 0) {
            this.formData.tiers[index].dfee = "0.00";
          }
        }
        let ccFee = cost * this.ccfee;

        this.formData.tiers[index].ccfee = ccFee.toFixed(2);
      } else if (type === "product") {
        for (let i = 0; i < this.tfees.length; i++) {
          let ele = this.tfees[i];
          if (cost >= ele.min && cost <= ele.max) {
            this.formData.products[index].tfee = ele.value.toFixed(2);
            i = this.tfees.length;
          } else if (cost === 0) {
            this.formData.products[index].tfee = "0.00";
          }
        }
        let ccFee = cost * this.ccfee;

        this.formData.products[index].ccfee = ccFee.toFixed(2);
      }
    },
    addTier(data, index) {
      if (data !== undefined) {
        let ogTier;
        if (this.isEditing) {
          ogTier = this.formData.tiers.find(t => t.saleDateId === data);
          if (!ogTier.hasOwnProperty("name")) {
            ogTier = this.tiersData.find(t => t.saleDateId === data);
            ogTier.tierId = ogTier.id;
            ogTier.bundlePrice = ogTier.price;
            ogTier.dfee ? ogTier.dfee : 0;
            ogTier.quantity = 1;
          }
        } else {
          ogTier = this.tiersData.find(t => t.saleDateId === data);
          ogTier.tierId = ogTier.id;
          ogTier.bundlePrice = ogTier.price;
          ogTier.dfee ? ogTier.dfee : 0;
          ogTier.quantity = 1;
        }

        this.formData.tiers[index] = ogTier;
        this.calculateTierFees(ogTier.bundlePrice, index, "tier");
      }
    },
    addRecurringTier(data, index) {
      let ogTier = this.tiersData.find(t => t.id === data.tierID);
      ogTier.type = data.type;
      ogTier.tierId = ogTier.id;
      ogTier.venueAccessDateID = data.venueAccessDateID;
      ogTier.venueAccessTimeID = data.venueAccessTimeID;
      ogTier.bundlePrice = ogTier.price;
      ogTier.quantity = 1;

      this.formData.tiers[index] = ogTier;
      this.calculateTierFees(ogTier.bundlePrice, index, "tier");
    },
    addProducts(data, index) {
      let ogProduct;
      // TODO Do duplicate validation

      if (this.isEditing) {
        ogProduct = this.formData.products.find(
          product => product.variantId === data
        );

        if (!ogProduct.hasOwnProperty("title")) {
          ogProduct = this.productsData.find(
            product => product.variantId === data
          );
          ogProduct.productId = ogProduct.product_id.$oid;
          ogProduct.bundlePrice = parseInt(ogProduct.price);
          ogProduct.price = ogProduct.price;
          ogProduct.quantity = 1;
        }
      } else {
        ogProduct = this.productsData.find(
          product => product.variantId === data
        );
        ogProduct.productId = ogProduct.product_id.$oid;
        ogProduct.bundlePrice = parseInt(ogProduct.price);
        ogProduct.price = ogProduct.price;
        ogProduct.quantity = 1;
      }
      this.formData.products[index] = ogProduct;
      this.calculateTierFees(ogProduct.bundlePrice, index, "product");
    },
    async submit() {
      this.isSaving = true;
      // Custom validation for tiers/products
      let tiers = this.formData.tiers;
      let products = this.formData.products;
      // Have to do this extra validation for empty tiers and products
      if (tiers.length + products.length >= 2) {
        let areRealTiers = tiers.every(
          t =>
            t.hasOwnProperty("name") &&
            t.hasOwnProperty("quantity") &&
            t.quantity > 0
        );
        let areRealProducts = products.every(
          p =>
            p.hasOwnProperty("title") &&
            p.hasOwnProperty("quantity") &&
            p.quantity > 0
        );

        if (!areRealTiers || !areRealProducts) {
          this.formError = true;
          this.isSaving = false;
          window.scrollTo(0, 0);

          return;
        }
        this.formError = false;
      } else if (tiers.length + products.length < 2) {
        this.formError = true;
        this.isSaving = false;
        window.scrollTo(0, 0);

        return;
      } else {
        this.formError = false;
      }

      try {
        let endPoint = this.isEditing
          ? "/planners/update-bundle-code"
          : "/planners/add-bundle-code";

        let bundleCode = {};
        if (this.isEditing) {
          const updatedBundleCode = this.createBundleCode(this.formData, false);

          bundleCode = {
            updatedBundleCode: updatedBundleCode
          };
        } else {
          bundleCode = this.createBundleCode(this.formData, true);
        }

        this.$axios
          .post(endPoint, bundleCode)
          .then(({ data }) => {
            let bundleCodeTableDisplay = this.createBundleCodeTableDisplay(
              data
            );

            if (this.isEditing) {
              const index = this.tableData.data.findIndex(
                t => t._id === bundleCodeTableDisplay._id
              );
              this.tableData.data.splice(index, 1, bundleCodeTableDisplay);
            } else {
              this.tableData.data.push(bundleCodeTableDisplay);
              this.incrementTableIndex();
            }

            let msg = this.isEditing
              ? "Your bundle has been successfully updated."
              : "Your bundle has been successfully created.";
            this.$toast.success(msg);
            this.isSaving = false;
            this.$emit("created");
            this.clearFormData();
          })
          .catch(error => {
            this.isSaving = false;
            console.log(error.response);
            this.$toast.error(error.response.data);
          });
      } catch (error) {
        this.isSaving = false;
        this.$toast.error(error.response.data);
        console.log("Error");
      }
    },
    edit(bulkCode) {
      this.isEditing = true;
      this.populateDataForm(bulkCode);
    },
    cancelEdit() {
      this.clearFormData();
    },
    clearFormData() {
      this.$formulate.resetValidation("bundleForm");
      this.$formulate.reset("bundleForm");
      this.formData = {
        tiers: [],
        products: [],
        codeAvailable: "",
        startDate: "",
        endDate: "",
        ccfee: 0,
        tfee: 0
      };
      this.isThereAPassword = false;
      this.isEditing = false;
    },
    redirect() {
      this.$router.push("/my-events");
    },
    createBundleCode(data, isFormData) {
      let tempBundleCode = {
        name: data.name,
        tiers: data.tiers,
        products: data.products,
        codeAvailable: parseInt(data.codeAvailable),
        startDate: data.startDate,
        endDate: data.endDate,
        isActive: true,
        url: this.eventUrl,
        password: data.password,
      };

      return isFormData
        ? { ...tempBundleCode, codeRemaining: parseInt(data.codeAvailable) }
        : {
            ...tempBundleCode,
            _id: this.bundleId,
            index: data.index,
            codeRemaining: parseInt(data.codeRemaining)
          };
    },
    getProductNameById(productList, bundleProducts) {
      let products = productList.filter(({ product_id: id1 }) =>
        bundleProducts.some(({ productId: id2 }) => id2 === id1.$oid)
      );

      let productsString = products.map(p => p.title).join(", ");
      return productsString;
    },
    getTierNameById(bundleTiers) {
      let tiers = this.tiersData.filter(({ saleDateId: id1 }) =>
        bundleTiers.some(({ saleDateId: id2 }) => id2 === id1)
      );

      let tiersString = tiers.map(p => p.name).join(", ");
      return tiersString;
    },
    createBundleCodeTableDisplay(bundleCode) {
      let productTitle = this.getProductNameById(
        this.productsData,
        bundleCode.products
      );
      let tierIdDisplay = this.getTierNameById(bundleCode.tiers);
      return {
        categoryName: BUNDLE_DISCOUNT,
        name: bundleCode.name,

        _id: this.isEditing ? bundleCode._id : bundleCode._id.$oid,
        tiers: bundleCode.tiers,
        tierIdDisplay: tierIdDisplay || "",

        products: bundleCode.products,
        productTitle: productTitle || "",

        codeAvailable: bundleCode.codeAvailable,
        codeRemaining: bundleCode.codeAvailable,

        startDate: bundleCode.startDate,
        endDate: bundleCode.endDate,
        isActive: true,
        edit: true,
        index: this.tableIndex,

        //table display
        // discountDisplay: discountDisplay,
        availableCodeDisplay:
          bundleCode.codeAvailable + "/" + bundleCode.codeAvailable,

        startDateDisplay: dateFormat(
          new Date(bundleCode.startDate),
          "ddd, mmm dS, yyyy"
        ),
        endDateDisplay: dateFormat(
          new Date(bundleCode.endDate),
          "ddd, mmm dS, yyyy"
        )
      };
    },
    populateDataForm(bundleCode) {
      this.isThereAPassword = false;
      let tz = dayjs.tz.guess();

      this.bundleId = bundleCode._id;
      this.formData.tiers = bundleCode.tiers;
      this.tierholders = bundleCode.tiers;
      this.formData.name = bundleCode.name;
      this.formData.products = bundleCode.products;
      this.formData.codeAvailable = bundleCode.code.available;
      this.formData.startDate = dateFormat(
        dayjs(bundleCode.startDate)
          .tz(tz)
          .toDate(),
        "dddd, mmmm d, yyyy, HH:MM:ss"
      );
      this.formData.password = bundleCode.password;
      if(bundleCode.password != ''){
        this.isThereAPassword = true;
      }
      this.formData.endDate = dateFormat(
        dayjs(bundleCode.endDate)
          .tz(tz)
          .toDate(),
        "dddd, mmmm d, yyyy, HH:MM:ss"
      );

      this.tierholders.forEach(t => {
        t.tierID = t.id;
      });
    },
    formatTierDate(tier) {
      const start = dayjs(+tier.sale_start.$date.$numberLong);
      const end = dayjs(+tier.sale_end.$date.$numberLong);

      return start.format("llll") + " - " + end.format("llll");
    }
  },
  async mounted() {
    try {
      const response = await this.$axios.post(
        "/planners/bundle-discount-checkout",
        {
          url: this.eventUrl
        }
      );

      if (response.status === 200) {
        let { products, tiers, eventEndDate, isRecurring } = response.data;
        this.eventEndDate = new Date(parseInt(eventEndDate.$date.$numberLong));
        this.isRecurringEvent = isRecurring;
        for (const tier of tiers) {
          tier.saleDates.forEach(t => {
            let date = this.formatTierDate(t);

            this.tiersData.push({
              ...tier,
              saleDateId: t.id,
              date: date,
              availableQuantity: t.qty
            });
            this.tierList.push({
              value: t.id,
              label: `${tier.name} - ${date} - $${tier.price.toFixed(2)}`
            });
          });
        }

        for (const product of products) {
          product.variants.forEach(v => {
            this.products.push({
              value: v.id,
              label: `${product.title} - ${v.name} - $${v.price.$numberDecimal}`
            });
          });
        }

        products.forEach(product => {
          let variants = product.variants;
          let p = product;

          variants.forEach(v => {
            this.productsData.push({
              ...p,
              variantId: v.id,
              variantName: v.name,
              price: v.price.$numberDecimal,
              ccfee: v.ccfee ? v.ccfee.$numberDecimal : "",
              tfee: v.tfee.$numberDecimal,
              availableQuantity: v.quantity
            });
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  created() {
    this.$axios
      .post(`/planners/retrieve-tier-fees`, {
        event: this.$route.params.url
      })
      .then(response => {
        if (response.status === 200) {
          let feeObj = response.data.fee_Obj;
          let ccFeeToUse =
            parseFloat(feeObj.feeData.ccFee.$numberDecimal) / 100;
          let feeArray = [];
          feeObj.ticketData.forEach(ticketFee => {
            let objToInsert = {
              min: parseFloat(ticketFee.startAmount.$numberDecimal),
              max: parseFloat(ticketFee.endAmount.$numberDecimal),
              value: parseFloat(ticketFee.price.$numberDecimal)
            };
            feeArray.push(objToInsert);
          });
          this.tfees = feeArray;
          this.ccfee = ccFeeToUse;
        }
      });
  }
};
</script>

<style lang="less">
.red {
  color: red;
  text-align: center;
  margin-bottom: 32px;

  p {
    margin: 0;
    font-size: 16px;
  }
}

.ticket-tier-container {
  display: flex;
  margin-right: 16px;
  // grid-template-columns: 95% 5%;
}

.ticket-tier-plus-sign {
  font-size: xx-large;
  color: #44b678;
  margin: auto;
  padding-top: 5px;
  padding-left: 12px;
  font-weight: 500;
}

.radio-button-container {
  .formulate-input-element {
    display: flex;
  }
}

.back-events {
  margin-left: auto;
}

.cancel-button {
  text-align: -webkit-right;
}

.formulate-input {
  &[data-classification="submit"] button {
    margin-left: initial;
  }
}

.list-bundle-container {
  .header,
  .items {
    display: grid;
    grid-template-columns: 2fr 160px 1fr 1fr;
    align-items: center;
    border-bottom: 1px solid #494c51;
    padding: 10px 0;
  }

  .quantity-input-container {
    max-width: 120px;
  }

  div.counter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .minus,
    .plus {
      font-size: 13px;
      background: var(--dropdown-background);
      color: var(--text);
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      border-radius: 14px;
      border: 1px solid var(--dropdown-border);
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

    input {
      width: 50%;
      height: 24px;
      padding: 12px 0;
      text-align: center;
      box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
      border: 1px solid transparent;
      background: var(--input-background);
      color: var(--text);
      border-radius: 4px;
      display: inline-block;
      vertical-align: middle;
      -webkit-appearance: none;
      outline: none;
      font-size: 16px;

      &:hover {
        border: 1px solid var(--input-border);
      }

      &:focus {
        color: var(--text);
        border: 1px solid #48bb8b;
        background-color: var(--active-background);
      }
    }
  }

  .price-input-container {
    max-width: 200px;

    .formulate-input {
      margin: 0;
    }

    .input-wrapper {
      margin-bottom: 0;
    }
  }
}

.row {
  &.btns {
    margin-top: 30px !important;
  }
}
</style>
