<template>
  <div class="bundle-discounts">
    <!-- <alert-modal confirm ref="confirmation" /> -->
    <Bundle
      :eventUrl="eventUrl"
      :tableData="bundleTableDatas"
      :tableIndex="bundleTableIndex"
      :incrementTableIndex="incrementBundleTableIndex"
      @created="refreshTable"
      ref="bundleComponent"
    />
    <div class="divider"></div>
    <div class="row">
      <div class="col full">
<!--        <table-tote v-model="bundleTableDatas"/>-->
        <DataTable
          :title="bundleTable.title"
          endpoint="/planners/get-bundles/:event"
          :args="{ event: eventUrl }"
          ref="table"
          :headers="bundleTable.columns"
          :custom-columns="['ordering','isActive', 'edit']"
          searchPlaceholder="Name or Email Search"
        >
          <template v-slot:[`item.ordering`]="{ item }">
            <div class="icon-wrapper">
              <font-awesome-icon
                class="icon"
                @click="moveUp(item)"
                icon="arrow-up"
              />
              <font-awesome-icon
                style="margin-left: 15px"
                class="icon"
                @click="moveDown(item)"
                icon="arrow-down"
              />
            </div>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
              <FormulateInput
                type="checkbox"
                ignored
                v-model="item.isActive"
                @input="updatePromoCode(item)"
                autocomplete="false"
              />
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <div class="icon-wrapper">
              <font-awesome-icon
                class="icon"
                @click="edit(item)"
                icon="edit"
              />
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Bundle from "../../components/discount/Bundle";
import TableTote from "@/components/TableTote.vue";
import dateFormat from "dateformat";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faEdit, faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AlertModal from "@/components/modals/AlertModal.vue";
import DataTable from "@/components/DataTable.vue";

const PROMO_DISCOUNT = "Promo Discount";
const BULK_DISCOUNT = "Bulk Discount";
const BUNDLE_DISCOUNT = "Bundled Discount";
const ALL_TICKET_TIERS = "All Ticket Tiers";
library.add(faEdit, faArrowUp, faArrowDown);

export default {
  components: {
    Bundle,
    TableTote,
    AlertModal,
    DataTable,
    FontAwesomeIcon
  },
  head() {
    return {
      title: "Bundles"
    };
  },
  data() {
    return {
      tierList: [{value: ALL_TICKET_TIERS, label: ALL_TICKET_TIERS}],

      eventUrl: this.$route.params.url,
      bundleTableDatas: {},
      bundleTableIndex: 0
    };
  },
  computed: {
    bundleCategoryDiscountTypeDisplay() {
      return this.categoryDiscountTypeButtons[2].checked;
    }
  },
  methods: {
    refreshTable(){
      this.$refs.table.refetchData();
    },
    moveUp(item) {
      this.$axios.post('/planners/bundles/up', {
        'id': item._id,
      }).then((response) => {
        this.$refs.table.refetchData();
      })
    },
    moveDown(item) {
      this.$axios.post('/planners/bundles/down', {
        'id': item._id,
      }).then((response) => {
        this.$refs.table.refetchData();
      })
    },
    async updatePromoCode(code, field) {
      try {
        const updatedBundleCodeActive = {...code, url: this.eventUrl};
        await this.$axios.post(
          "/planners/update-bundle-code-active",
          updatedBundleCodeActive
        );
      } catch {
        console.log("error activating bundle");
      }
    },
    incrementBundleTableIndex() {
      this.bundleTableIndex++;
    },
    getProductNameById(productList, bundleProducts) {
      let products = productList.filter(({product_id: id1}) =>
        bundleProducts.some(({productId: id2}) => id2 === id1)
      );

      let productsString = products.map(p => p.title).join(", ");
      return productsString;
    },
    getTierNameById(tierId) {
      let tier = this.tierList.find(t => t.value === tierId);
      return tier.label;
    },
    getTiers(bundleTiers) {
      let tiers = this.tierList.filter(({value: id1}) =>
        bundleTiers.some(({saleDateId: id2}) => id2 === id1)
      );

      let tiersString = tiers.map(p => p.label).join(", ");
      return tiersString;
    },
    edit(code, field) {
      this.$refs.bundleComponent.edit(code);
      window.scrollTo({top: 10, behavior: "smooth"});
    }
  },
  async mounted() {
    // this.$refs.confirmation.open({
    //   title: "Maintenance Underway",
    //   msg: "Click okay to go back to the dashboard.",
    //   onConfirm: () => {
    //     this.$loader.stop();
    //     this.active = false;
    //     document.body.style.overflow = "auto";
    //     this.$router.push("/dashboard");
    //   }
    // });
    try {
      let bundleDatas = [];
      const response = await this.$axios.post(
        "/planners/bundle-discount-checkout",
        {
          url: this.eventUrl
        }
      );

      if (response.status === 200) {
        const {bundleDiscounts, products, tiers} = response.data;
        for (const tier of tiers) {
          tier.saleDates.forEach(t => {
            this.tierList.push({
              value: t.id,
              label: tier.name
            });
          });
        }
        for (const bundleCode of bundleDiscounts) {
          let productTitle = this.getProductNameById(
            products,
            bundleCode.products
          );
          let tierIdDisplay = this.getTiers(bundleCode.tiers);

          bundleDatas.push({
            categoryName: BUNDLE_DISCOUNT,
            _id: bundleCode._id,

            name: bundleCode.name,

            tiers: bundleCode.tiers,
            tierIdDisplay: tierIdDisplay || "",

            products: bundleCode.products,
            productTitle: productTitle || "",

            codeAvailable: bundleCode.code.available,
            codeRemaining: bundleCode.code.remaining,

            startDate: bundleCode.start_date,
            endDate: bundleCode.end_date,

            isActive: bundleCode.isActive,
            edit: true,
            index: this.bundleTableIndex++,

            //table display
            // discountDisplay: discountDisplay,
            availableCodeDisplay:
              bundleCode.code.remaining + "/" + bundleCode.code.available,

            descriptionDisplay: "Buy " + " ticket tier plus " + productTitle,

            startDateDisplay: dateFormat(
              new Date(bundleCode.start_date),
              "ddd, mmm dS, yyyy"
            ),
            endDateDisplay: dateFormat(
              new Date(bundleCode.end_date),
              "ddd, mmm dS, yyyy"
            )
          });
        }
      }

      this.bundleTable = {
        title: "Bundle Discounts",
        columns: [
          {text: 'Ordering', value: 'ordering'},
          {text: "Name", value: "name"},
          {text: "Tiers", value: "tierIdDisplay"},
          {text: "Products", value: "productTitle"},
          {text: "Available", value: "availableCodeDisplay"},
          {text: "Start Date", value: "start_date"},
          {text: "End Date", value: "end_date"},
          {text: "Active", checkbox: true, value: "isActive"},
          {text: "Edit", icon: true, value: "edit"}
        ]
      }
      this.bundleTableDatas = {
        title: "Bundle Discounts",
        columns: [
          { title: "Name", field: "name", width: "10%" },
          { title: "id", field: "_id", hidden: true },

          { title: "Tiers", field: "tierIdDisplay", width: "20%" },
          { title: "Products", field: "productTitle", width: "15%" },
          {
            title: "Available",
            field: "availableCodeDisplay",
            width: "10%"
          },
          {
            title: "Start Date",
            field: "startDateDisplay",
            width: "15%",
            date: true
          },
          {
            title: "End Date",
            field: "endDateDisplay",
            width: "15%",
            date: true
          },

          { title: "Active", field: "isActive", width: "5%", checkbox: true },
          { title: "Edit", field: "edit", width: "5%", icon: true }
        ],
        data: bundleDatas,
        searchable: ["tierIdDisplay", "descriptionDisplay"],
        callbacks: {
          isActive: this.updatePromoCode,
          edit: this.edit
        },
        icons: {
          edit: (row, column, library) => {
            library.add(faEdit);
            return "edit";
          }
        }
      };
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style lang="less" scoped>
.icon-wrapper {
  position: relative;
  cursor: pointer;
  .icon {
    color: var(--primary-green);
    font-size: small;
  }
  .icon-tool-tip {
    position: absolute;
    left: 20px;
    top: 10px;
    width: 130px;
    display: none;
  }
}
</style>
